$(document).on('click', '.menu', function() {
    $('.fixed-menu').addClass('active')
})

$(document).on('click', '.close-menu', function() {
    $('.fixed-menu').removeClass('active')
})

$(document).on('click', '.main-url', function() {
    $('.main-url').toggleClass('active')
    $('.sub-menu').toggleClass('active')
    $('.menu-item-79').toggleClass('active')
})
