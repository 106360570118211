window.onload = function(){

    $('.preloader-wrapper').addClass('out')
    $('.preloader-wrapper').fadeOut(400)

    setTimeout(function(){
        var timer = 0

        $('.mobile-menu').css('transition', '0.4s')

        $('.glass-icon').each(function(){
            var elem = $(this)

            setTimeout(function(){
                elem.addClass('animate')
            }, timer)

            timer += 200
        })
    }, 600)

}