function headerScroll(){
    if($(window).scrollTop()){
        $('header.site-header').addClass('scroll')
    }else{
        $('header.site-header').removeClass('scroll')
    }
}

headerScroll()

$(window).scroll(headerScroll);

$(document).on('click', '.open-menu', function(){
    $('.mobile-menu').addClass('show')
})

$(document).on('click', '.close-menu', function(){
    $('.mobile-menu').removeClass('show')
})

$(document).click(function (e) {
    var menu = $('.menu-group')

    if ( !menu.is(e.target) && menu.has(e.target).length === 0) {
        $('.mobile-menu').removeClass('show')
    }
})