const gold = {
	"async": true,
	"crossDomain": true,
	"url": "https://yh-finance.p.rapidapi.com/stock/v2/get-summary?symbol=GC%3DF&region=US",
	"method": "GET",
	"headers": {
		"x-rapidapi-host": "yh-finance.p.rapidapi.com",
		"x-rapidapi-key": "54136a6fc0mshefeb470f4acfe22p1147c6jsn730c15a3201d"
	}
};

const euro = {
	"async": true,
	"crossDomain": true,
	"url": "https://yh-finance.p.rapidapi.com/stock/v2/get-summary?symbol=EURKZT%3DX&region=US",
	"method": "GET",
	"headers": {
		"x-rapidapi-host": "yh-finance.p.rapidapi.com",
		"x-rapidapi-key": "54136a6fc0mshefeb470f4acfe22p1147c6jsn730c15a3201d"
	}
};


const usd = {
	"async": true,
	"crossDomain": true,
	"url": "https://yh-finance.p.rapidapi.com/stock/v2/get-summary?symbol=KZT%3DX&region=US",
	"method": "GET",
	"headers": {
		"x-rapidapi-host": "yh-finance.p.rapidapi.com",
		"x-rapidapi-key": "54136a6fc0mshefeb470f4acfe22p1147c6jsn730c15a3201d"
	}
};

const oil = {
	"async": true,
	"crossDomain": true,
	"url": "https://yh-finance.p.rapidapi.com/stock/v2/get-summary?symbol=CL%3DF&region=US",
	"method": "GET",
	"headers": {
		"x-rapidapi-host": "yh-finance.p.rapidapi.com",
		"x-rapidapi-key": "54136a6fc0mshefeb470f4acfe22p1147c6jsn730c15a3201d"
	}
};


Data = new Date();
Hour = Data.getHours();
Minutes = Data.getMinutes();


if((Hour == 23) || (Hour == 06) || (Hour == 12) || (Hour == 18)) {

	$.ajax(oil).done(function (response) {
		var eu = $('.oil'), 
			oil = response.summaryDetail.open.raw

		requestAjax('stocks_oi', {oil: oil}, function(res) {
			console.log(res)
		})
	});
	
	$.ajax(usd).done(function (response) {
		var eu = $('.dollar'), 
			dollar = response.summaryDetail.open.raw

			
		requestAjax('stocks_dol', {dollar: dollar}, function(res) {
			console.log(res)
		})
	});
	
	$.ajax(euro).done(function (response) {
		var eu = $('.euro'), 
			euro = response.summaryDetail.open.raw
			
		requestAjax('stocks_eu', {euro: euro}, function(res) {
			console.log(res)
		})
	});
	
	$.ajax(gold).done(function (response) {
		var eu = $('.gold'), 
			gold = response.summaryDetail.open.raw
			
		requestAjax('stocks_gold', {gold: gold}, function(res) {
			console.log(res)
		})
	});
}

