$(document).on('click', '#send', function(e){
    var btn = $(this),
        form = btn.parents('form'),
        popup = form.parents('.popup'),
        inputs = form.find('.required')
    
    inputs.each(inputCheck)
    e.preventDefault()

    if(!form.find('.error').length){
        var user_name = $.trim($('#user_name').val()),
            phone = $.trim($('#phone')[0].mask.unmaskedValue),
            comment = '',
            results = {
                user_name,
                phone,
                comment
            }
        
        btn.attr('disabled', true)

        sendAjax('add_order', results, function(){
            btn.attr('disabled', false)
            inputs.each(resetInput)
            addNotif('Спасибо!', 'Ваша заявка успешно отправлена.', 'success')
            closePopup(popup)
        },function(res){
            btn.attr('disabled', false)
            addNotif('Ошибка!', 'Что-то пошло не так.', 'error')
            displayErrors(res.text_error)
        })
    }

    e.preventDefault()
})

$(document).on('click', '.send-request', function(e){
    var btn = $(this),
        form = btn.parents('form'),
        inputs = form.find('.required')
    
    inputs.each(inputCheck)
    e.preventDefault()

    if(!form.find('.error').length){
        var user_name = $.trim(form.find('.name').val()),
            phone = $.trim(form.find('.phone')[0].mask.unmaskedValue),
            comment = '',
            results = {
                user_name,
                phone,
                comment
            }
        
        btn.attr('disabled', true)

        sendAjax('add_order', results, function(){
            btn.attr('disabled', false)
            inputs.each(resetInput)
            addNotif('Спасибо!', 'Ваша заявка успешно отправлена.', 'success')
        },function(res){
            btn.attr('disabled', false)
            addNotif('Ошибка!', 'Что-то пошло не так.', 'error')
            displayErrors(res.text_error)
        })
    }

    e.preventDefault()
})