$(document).ready(function () {
    
    $('.gallery-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        prevArrow: '<button class="btn with-bg icon icon-left-arrow prev"></button>',
        nextArrow: '<button class="btn with-bg icon icon-right-arrow next"></button>',
    })

});

var swiper = new Swiper(".stocks-line", {
    direction: "vertical",
    autoplay: {
        delay: 2000,
        disableOnInteraction: false,
    },
});