function inputCheck(){
    var elem   = $(this),
        type   = elem.data('type'),
        val    = elem.val(),
        parent = elem.parents('.form-group')    

    if(parent.length == 0){
        parent = elem.parents('.ask-line')
    }

    if(!type || type == 'undefined'){
        type = 'text'
    }
    
    parent.removeClass('error')
    parent.find('.error-message').remove()

    function message(text){        
        if(!text){
            text = 'Заполните поле'
        }

        if(!parent.hasClass('no-err-msg')){
            parent.append('<p class="error-message">' + text + '</p>')
        }
    
    }
        
    function type_name(){
        var check = val.replace(/[^a-zA-Z0а-яА-ЯГгҚқҒғӘәҢңӨөҰұҮүҺһІіЭэ\- ]/gi, '')       
        
        if(val.length != check.length || val == '' || val.length > 16 || val.length < 2){
            parent.addClass('error')
            message('Введите имя корректно')
        }
    
    }

    function type_dropzone(){
        
        if(!parent.find('.dz-preview').length){
            parent.removeClass('success')
            parent.addClass('error')
            message('Загрузите файл')
        }

    }

    function type_balance(){
        val = val.replace(/[^0-9]/gm, '')

        console.log(val)

        if(val < 1000 || val == '' || isNaN(val)){
            parent.addClass('error')
            message('Минимальная сумма 1000 тг.')
        }
    }

    function type_company_name(){
        var clean = val.replace(/[^a-zA-Z0а-яА-ЯГгҚқҒғӘәҢңӨөҰұҮүҺһІіЭэ\- ]/gi, '')

        if(val == '' || val.length < 3){
            parent.addClass('error')
            message('Заполните поле')
        }else if(clean.length == $.trim(val).length){
            parent.addClass('error')
            message('Заполните поле корректно')
        }
    
    }

    function type_surname(){
        var check = val.replace(/[^a-zA-Z0а-яА-ЯГгҚқҒғӘәҢңӨөҰұҮүҺһІіЭэ\- ]/gi, '')          
        
        if(val.length != check.length || val == '' || val.length > 40 || val.length < 2){
            parent.addClass('error')
            message('Введите фамилию корректно')
        }
    
    }

    function type_phone(){
        if( val.indexOf('_') > 0 ){
            parent.addClass('error')
            message()
        }else{
            for( var i = 0 ; i < 10 ; i++){
                var check = val.split(i).length - 1
                if(check > 9){
                    parent.addClass('error')
                    message('Введите номер корректно')
                }
            }
        }
    
    }

    function type_select(){            
        var selected = elem.attr('data-id')

        if(!elem.prop('disabled')){
            if(!val || !selected){
                parent.addClass('error')
                message('Выберите что-то')
            }
        }
    }

    function type_radio(){            
        var selected = parent.find('input:checked').length

        if(selected == 0){
            parent.addClass('error')
            message('Выберите что-то')
        }
    }

    function type_text(){     
               
        if(val == ''){
            parent.addClass('error')
            message()
        }
    }

    function type_password(){     
               
        if(val == ''){
            parent.addClass('error')
            message()
        }
    }

    function type_map(){     
               
        if(val == ''){
            parent.addClass('error')
            message('Выберите точку')
        }
    }

    function type_email(){        
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            emailTest = regex.test(val)

        if(!emailTest && val == ''){
            parent.addClass('error')
            message('Введите email корректно')   
        }else if(!emailTest){
            parent.addClass('error')
            message('Введите email')   
        }
    }

    function type_checkbox(){
        parent.find('.error-message').remove()

        if(!elem.prop('checked')){
            parent.addClass('error')
            parent.addClass('animate')

            setTimeout(function(){
                parent.removeClass('animate')
            }, 400)

            if(parent.hasClass('ask-question-checkbox')){
                message('Вы не приняли условия соглашения') 
            }

        }
        elem.click(function(){
            parent.removeClass('error')
        })
    }

    function type_confirm_pass(){        
        var pass = $('input[type="password"]').eq(0)

        if(val != pass.val()){
            parent.addClass('error')
            message('Пароли не совпадают')
        }else if(val == ''){
            parent.addClass('error')
        }
    }
    
    eval( 'type_' + type + '()' )

    if(elem.hasClass('l-check') && !parent.hasClass('error')){
        parent.addClass('success')
    }else{
        parent.removeClass('success')
    }
}

function resetInput(){
    var elem   = $(this),
        parent = elem.parents('.form-group'),
        type   = elem.data('type'),
        error  = parent.find('.error-message')

    elem.prop('selected', false)
    error.remove()
    parent.removeClass('error')
    elem.val('')
}

function displayErrors(array){
    $.each(array, function(key, value){
        if(key == 'errors'){
            $('.form-group').eq(0).before(`
                <div class="message icon-error error">
                    <div class="text">
                        <h6>Ошибка</h6>
                        <p>` + value + `</p>
                    </div>
                </div> 
            `)
        }else{
            var elem = $('#' + key),
                parent = elem.parent()

            parent.addClass('error')
            parent.find('.error-message').remove()
            parent.append('<p class="error-message">' + value + '</p>')
        }
    })
}

$('.l-check').on('change', function(){
    $(this).each(inputCheck)
})

$('input').on('input change', function(){
    $(this).parents('.form-group').removeClass('error')
})