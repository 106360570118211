$('.js-tab-trigger').click(function() {
    var elem = this,
        id = $(this).attr('data-tab'),
        content = $('.js-tab-content[data-tab="'+ id +'"]'),
        parents = $(elem).parents('.tabs'),
        triger = parents.find('.js-tab-trigger.active'),
        contents = parents.find('.js-tab-content.active');
    
    $(triger).removeClass('active'); // 1
    $(this).addClass('active'); // 2
    
    $(contents).removeClass('active'); // 3
    content.addClass('active'); // 4
}); 



$(document).ready(function() {
    currentTab();
    $('#tabs-sec').on('click', '.tab-header a', function(e) {
        if ($(this).attr("href")[0] == "#") {
            e.preventDefault();
            history.replaceState(null, null, $(this).attr("href"));
            currentTab();
        }
    });
});

function currentTab() {
    var href = location.hash || $('#tabs-sec .tab-header a').eq(0).attr("href");
    $('#tabs-sec .tab-header a').removeClass('active');
    $('#tabs-sec .tab-header a[href="' + href + '"]').addClass('active');
    $('.tabs-content > div').hide();
    $(href).show();
}

$(document).on('click', '.sub-url', function() {
    var elem = $(this),
        url =  elem.attr('href');

    location.href = url;
    window.location.reload()
})